<template>
    <div 
        class="modal fade"
        :id="id"
        tabindex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
    >
        <div class="modal-dialog modal-dialog-centered" :class="mw">
            
            <div class="modal-content rounded">
                <div class="modal-header"
                    :class="{'pb-0 border-0': !requireHeaderLine}"
                    :id="`${id}_header`"
                >
                    <h2 class="fw-bolder">{{title}}</h2>
                    <div
                        :id="`${id}_close`"
                        data-bs-dismiss="modal"
                        class="btn btn-icon btn-sm btn-active-icon-primary"
                    >
                        <span class="svg-icon svg-icon-1">
                        <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <!-- <div class="modal-body py-10 px-lg-17"> -->
                <div class="modal-body scroll-y px-10 px-lg-15 pb-15">
                    <!-- <div
                        :id="`${id}_scroll`"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#type_fishing_form_header"
                        data-kt-scroll-wrappers="#type_fishing_form_scroll"
                        data-kt-scroll-offset="300px"
                    > -->
                    <Form as="el-form" :validation-schema="validationSchema" :initial-values="initialValues" @submit="onSubmit" ref="form">
                        <slot></slot>
                        <button hidden type="submit" ref="submitBtn"></button>
                    </Form>
                    <!-- </div> -->
                </div>
                <div v-if="requireFooter" class="modal-footer flex-right">
                     <button
                        type="reset"
                        class="btn btn-light me-3"
                        :id="`${id}_close`"
                        data-bs-dismiss="modal"
                        ref="btnClose"
                    >
                        {{ textCloseButton }}
                    </button>
                    <button 
                        v-if="getShowExtraButton" 
                        type="reset" 
                        class="btn btn-light me-3" 
                        :id="`${id}_close`"
                        data-bs-dismiss="modal" 
                        @click="handleClickNavBtn('X')"
                    >
                        {{ textExtraButton }}
                    </button>
                    <button v-if="showSaveButton" @click="clicFakeSubmit"
                        class="btn btn-lg btn-primary"
                    >
                        <span class="indicator-label">
                            {{ textSaveButton }}
                            <span class="svg-icon svg-icon-3 ms-2 me-0">
                                <inline-svg src="icons/duotune/arrows/arr064.svg" />
                            </span>
                        </span>
                            <span v-if="isSaving" class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </button>
                </div>
            </div>
    
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, ref, toRefs } from "vue";
import { Form } from 'vee-validate';

export default defineComponent({
    name: "modal-form-master",
    components: {
        Form
    },
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        id: {
            type: String,
            required: true
        },
        fnCallbackSubmit: {
            type: Function,
            required: false
        },
        validationSchema: {
            type: Object,
            required: true
        },
        initialValues: {
            type: Object,
            required: false
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: () => false
        },
        showBackButton: {
            type: Boolean,
            required: false,
            default: () => false
        },
        showNextButton: {
            type: Boolean,
            required: false,
            default: () => false
        },
        showExtraButton: {
            type: Boolean,
            required: false,
            default: () => false
        },
        textSaveBtn: {
            type: String,
            required: false,
            default: () => 'Guardar'
        },
        textCloseBtn: {
            type: String,
            required: false,
            default: () => 'Cancelar'
        },
        textExtraBtn: {
            type: String,
            required: false,
            default: () => 'Cerrar'
        },
        mw: {
            type: String,
            required: false,
            default: () => 'mw-650px'
        },
        requireHeaderLine: {
            type: Boolean,
            required: false,
            default: () => true
        },
        requireFooter: {
            type: Boolean,
            required: false,
            default: () => true
        }
    },
    setup(props, context) {
        const btnClose = ref(null);
        const loading = ref(false);
        const { readOnly, showBackButton, showNextButton, showExtraButton } = toRefs(props);
        const form = ref();
        const submitBtn = ref(null);

        const clicFakeSubmit = () => {
            if(submitBtn.value) {
                submitBtn.value.click();
            }
        }

        const onSubmit = async(values) => {
            if(props.fnCallbackSubmit) {
                loading.value = true;
                await props.fnCallbackSubmit(values);
            }
        }

        const closeModal = () => {
            if(btnClose.value) {
                loading.value = false;
                btnClose.value.click();
            }
        }
        const disabledLoading = () => {    
            loading.value = false;
        }

        const handleClickNavBtn = (type) => {
            closeModal();
            context.emit("onClickNavBtn", type);
        }

        const showSaveButton = computed(() => {
            return !readOnly.value;
        });

        const textCloseButton = computed(() => {
            return readOnly.value ? "Cerrar" : props.textCloseBtn;
        });

        const textSaveButton = computed(() => {
            return props.textSaveBtn;
        });

        const textExtraButton = computed(() => {
            return props.textExtraBtn;
        });

        const isSaving = computed(() => loading.value);

        const getShowBackButton = computed(() => showBackButton.value);
        const getShowNextButton = computed(() => showNextButton.value);
        const getShowExtraButton = computed(() => showExtraButton.value);

        return {
            btnClose,
            submitBtn,
            loading,
            showSaveButton,
            getShowBackButton,
            getShowNextButton,
            textSaveButton,
            textCloseButton,
            textExtraButton,
            getShowExtraButton,
            isSaving,
            form,
            clicFakeSubmit,
            onSubmit,
            closeModal,
            handleClickNavBtn
            , disabledLoading
        }
    }
});
</script>