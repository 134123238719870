<template>
    <div class="fv-row rowDivControl" :style="`width: ${widht}px;`">
        <div class="fv-row" :class="{ itemDivControl: !haveTwoColums }">
            <slot></slot>
        </div>
        <div v-if="haveTwoColums" class="fv-row itemDivControl padd">
            <slot name="col2"></slot>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, toRefs } from 'vue'

export default defineComponent({
    name: "row-form",
    props:{
        twoColums: {
            type: Boolean,
            required: false,
            default: () => false
        },

        widht: {
            type: String,
            required: false,
            default: () => "510"
        }
    },
    setup(props) {
        const { twoColums } = toRefs(props);

        const haveTwoColums = computed(() => {
            return twoColums;
        });

        return {
            haveTwoColums
        }
    },
})
</script>

<style lang="scss">
    // .itemControl {
    //     min-width: 250px;
    // }

    .rowDivControl {
        display: flex;
        justify-content: space-between;
    }

    .itemDivControl {
        display: flex;
        flex-direction: column;
    }

    .padd {
        padding-left: 10px;
    }
</style>
