<i18n>
{
    "es": {
        "BUTTOMS": {
            "FILTER":"Filtros"
        }
    }
}
</i18n>
<template>
<div class="card">
    <div v-if="requireSearchField || requireAddButton || useExtraButtons || useFilters" class="card-header border-0 pt-6">
        <div class="card-title">
            <div class="d-flex align-items-center position-relative my-1">
                <span v-if="requireSearchField" class="svg-icon svg-icon-1 position-absolute ms-6">
                    <inline-svg src="media/icons/duotune/general/gen021.svg" />
                </span>
                <input v-if="requireSearchField" type="text" v-model="searchText" 
                class="form-control form-control-solid w-250px ps-15"
                 :placeholder="labels.placeHolderToFieldSearch" />
            </div>
            <slot name="extraFilters"></slot>
        </div>
        <div class="card-toolbar">
            <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                <slot name="extra-buttons"></slot>
                <div  class="me-4"></div>
                <slot name="extraActions" class="me-4"></slot>
                <button v-if="requireAddButton" type="button" class="btn btn-primary btn-active-light-primary" @click="handleCrudAction('N')">
                    <i class="bi bi-plus text-center fs-1 p-0 m-0"></i>
                    {{ labels.titleNewCrudAction }}
                </button>
                <div v-show="useFilters" class="me-4"></div>
                <button v-show="useFilters"
                      type="button"
                      class="btn btn-sm  btn-primary btn-active-light-primary"
                    data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      data-kt-menu-flip="top-end"
                    >
                      <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
                      <inline-svg src="media/icons/duotune/general/gen031.svg" />
                    </span>
                    {{$t("BUTTOMS.FILTER") }}
                </button>
                <slot name="filters"></slot>
            </div>
        </div>
    </div>
    <div class="card-body pt-5">
      <slot></slot>
    </div>
    <slot name="paginator"></slot>
    <Paginator
        v-if="requirePaginator"
        :numItems="numRecords" 
        :itemsPerPage="recordsPerPage"
        :selectedPage="currentSelectedPage"
        @handleClickPage="handleClickPage"
    />
    
</div>
</template>

<script>
import { defineComponent, toRefs, ref, computed, watch } from 'vue';
import Paginator from "../c-paginator/Paginator.vue";

export default defineComponent({
    name: "module-master",
    components: {
        Paginator
    },
    props: {
        requireSearchField: {
            type: Boolean,
            required: false,
            default: () => true
        },
        requireAddButton: {
            type: Boolean,
            required: false,
            default: () => true
        },
        useFilters: {
            type: Boolean,
            default: false
        },
        labels: {
            type: Object,
            required: false,
            default() { return {} }
        },
        requirePaginator: {
            type: Boolean,
            required: false,
            default: () => true
        },
        numItemsPaginator: {
            type: Number,
            required: false,
            default: () => 0
        },
        itemsPerPage: {
            type: Number,
            required: false,
            default: () => 5
        },
        selectedPage: {
            type: Number,
            required: false,
            default: () => 0
        },
        fnCallbackChangePage: {
            type: Function,
            required: false
        },
        fnCallbackCrudAction: {
            type: Function,
            required: false
        },
        fnCallbackSearchItems: {
            type: Function,
            required: false
        },
        useExtraButtons: {
            type: Boolean,
            default: false
        },
    },
    setup(props) {
        //Constantes y varaibles
        let searchText = ref("");
        let currentPage = ref(1);
        const { numItemsPaginator, itemsPerPage, selectedPage} = toRefs(props);

        //WATCHS
        watch(selectedPage, (value) => {
            currentPage.value = selectedPage.value;
        });

        watch(searchText, async (value) => {
            if(props.fnCallbackSearchItems) {
                await props.fnCallbackSearchItems(value);
            }
        });

        //VARAIBLES COMPUTADAS
        const numRecords = computed(() => {
            return numItemsPaginator.value;
        });

        const recordsPerPage = computed(() => {
            return itemsPerPage.value;
        });

        const currentSelectedPage = computed(() => {
            return currentPage.value;
        });

        //Funciones
        const handleClickPage = (page) => {
            currentPage.value = page;
            if(props.fnCallbackChangePage) {
                props.fnCallbackChangePage(page);
            }
        }

        const handleCrudAction = (action, id) => {
            if(props.fnCallbackCrudAction) {
                props.fnCallbackCrudAction(action, id);
            }
        }

        return {
            searchText,
            recordsPerPage,
            numRecords,
            currentSelectedPage,
            handleClickPage,
            handleCrudAction
        }
    },
})
</script>
