<template>
    <div v-if="requireV" class="btn btn-icon btn-sm btn-active-icon-primary" @click="handleActionClick('V')">
        <span class="svg-icon" :class="getIconSize">
            <inline-svg src="media/icons/duotune/files/fil024.svg" />
        </span>
    </div>
    <div v-if="requireM" class="btn btn-icon btn-sm btn-active-icon-primary" @click="handleActionClick('M')">
        <span class="svg-icon" :class="getIconSize">
            <inline-svg src="media/icons/duotune/art/art005.svg" />
        </span>
    </div>
    <div v-if="requireE" class="btn btn-icon btn-sm btn-active-icon-primary" @click="handleActionClick('E')">
        <span class="svg-icon" :class="getIconSize">
            <inline-svg src="media/icons/duotune/general/gen019.svg" />
        </span>
    </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
    name: "crud-actions",
    props: {
        itemID: {
            type: String,
            required: true
        },
        callbackCrudAction: Function,
        requireM: {
            type: Boolean,
            required: false,
            default: () => true
        },
        requireV: {
            type: Boolean,
            required: false,
            default: () => true
        },
        requireE: {
            type: Boolean,
            required: false,
            default: () => true
        },
        iconSize: {
            type: Number,
            required: false,
            default: () => 3
        }
    },
    setup(props) {
        const getIconSize = computed(() => `svg-icon-${props.iconSize}`)
        const handleActionClick = (action) => {
            if(props.callbackCrudAction) {
                props.callbackCrudAction(action, props.itemID);
            }
        }

        return {
            getIconSize
            , handleActionClick
        }
    },
})
</script>
